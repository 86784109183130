// import { VirtualItem } from '@tanstack/react-virtual';
import { Td } from '@chakra-ui/react';
import { ColumnType } from './types';

// use basic <td> and <td> for performance issues
const OffsetTr = ({
  columns,
  isSelectVisible,
  padding,
  // paddingLeft,
  // paddingRight,
  // virtualColumns,
}: {
  columns: ColumnType[];
  isSelectVisible: boolean;
  padding: number;
  // paddingLeft: number;
  // paddingRight: number;
  // virtualColumns: VirtualItem[];
}) =>
  padding > 0 ? (
    <tr style={{ height: `${padding}px` }}>
      {isSelectVisible /*&& paddingLeft === 0*/ && <Td width={14} />}
      {/*{paddingLeft > 0 && <th style={{ width: `${paddingLeft}px` }} />}*/}
      {columns.map((column) => {
        // const column = columns[virtualColumn.index];
        return <Td key={column.id} width={column.size ?? 'auto'} />;
      })}
      {/*{paddingRight > 0 && <td style={{ width: `${paddingRight}px` }} />}*/}
    </tr>
  ) : null;

export default OffsetTr;
