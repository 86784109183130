import { Text } from '@chakra-ui/react';
import { ComponentProps } from 'components/VirtualizedTable';

const TextCell = ({ children }: ComponentProps) => (
  <Text variant="body.mediumBold" noOfLines={2}>
    {children}
  </Text>
);

export default TextCell;
