import { Td } from '@chakra-ui/react';
import { ColumnType } from './types';

interface EmptyTrProps {
  columns: ColumnType[];
  isSelectVisible: boolean;
}

// use basic <td> and <td> for performance issues
const EmptyTr = ({ columns, isSelectVisible }: EmptyTrProps) => (
  <tr>
    {isSelectVisible && <Td width={14} height={0} py={0} />}
    {columns.map((column) => (
      <Td key={column.id} width={column.size ?? 'auto'} height={0} py={0} />
    ))}
  </tr>
);
export default EmptyTr;
